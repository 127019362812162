import { makeObservable, observable, action, computed, set, keys, values, has, remove, entries } from "mobx";
import WordHash from "wordhash";

let wordHash = WordHash();

export class DeviceStore extends Object {
    value = {};

    constructor(devices = []) {
        super();
        makeObservable(this, {
            value: observable,
            add: action,
            set: action,
            remove: action,
            count: computed,
            ids: computed,
            values: computed,
            entries: computed,
            empty: computed
        }).add(devices);
    }

    // Add devices(s) to the store
    add(devices) {
        set(this.value, Object.fromEntries((Array.isArray(devices) ? devices : [devices]).map(i => {
            return (i instanceof Device ? i : new Device(i)).kv;
        })))
    }

    set(notifications) {
        this.value = {}
        this.add(notifications);
    }

    // Remove device(s) from the store
    remove(devices) {
        return this.update(devices, false)
    }

    // Update device(s) in the store
    update(devices, update = true) {
        let items = (Array.isArray(devices) ? devices : [devices]).map(i => {
            let item = i instanceof Device ? i : new Device(i);
        });

        let patch = items.reduce((acc, i) => {
            return {
                ...acc,
                [i.id]: i
            }
        }, {});

        set(this.value, {
            ...Object.fromEntries(entries(this.value).filter(([key]) => key in patch || items.find(i => i?.old === key))),
            ...update ? patch : {},
        });

        return update ?
            items.every(i => this.has(i))
            :
            items.some(i => !this.has(i))
    }

    has(id) {
        return has(this.value, id instanceof Device ? id.id : id)
    }

    get count() {
        return keys(this.value).length
    }

    get ids() {
        return keys(this.value)
    }

    get values() {
        return values(this.value)
    }

    get entries() {
        return entries(this.value)
    }

    get empty() {
        return keys(this.value) <= 0
    }
}

export class Device extends Object {
    value = {};

    constructor(content = {}) {
        super();
        makeObservable(this, {
            value: observable,
            id: computed,
            old: computed,
            ua: computed,
            created_at: computed,
            kv: computed,
            name: computed,
        })
        Object.assign(this.value, {
            ...(content || {}),
            hashed: wordHash.hash(content?.id),
        });
    }

    get id() {
        return this.value.id
    }

    get old() {
        return this.value.old ?? null;
    }

    get ua() {
        return this.value.ua
    }

    get created_at() {
        return this.value.created_at
    }

    get name() {
        return this.value.hashed
    }

    get kv() {
        return [this.id, this]
    }
}