import {
    Outlet,
    Link,
    useMatches,
    useNavigate,
} from "react-router-dom";
import { useState, useEffect } from 'react';
import { Moon, Sun, Menu, X, Github, Twitter, Linkedin, HomeIcon, User, Mail, Settings, HelpCircle, ChevronRight, ChevronLeft, PanelLeft, PanelLeftClose } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Nav, MobileNav } from '@/components/Nav';
import { Sidenav } from '@/components/SideNav';
import { observer } from 'mobx-react-lite';
import { observable, runInAction } from "mobx";
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { Website, Sync } from '@/lib/settings';
import { Toaster } from "@/components/ui/sonner";
import { useEventSource, useEventSourceListeners } from "@/lib/hooks/eventSource";
import { Notifications, Devices, Toasts } from "@/lib/stores";
import { useBrowserStore } from "@/lib/hooks/browserStore";
import { toast } from "sonner";
import { set } from "mobx";
import { isTokenExpired } from "@/lib/utils";
import ErrorBoundary from '@/components/ui/custom/errorboundary';

const Layout = observer(({ store }) => {
    if (!store.Auth?.isAuthenticated) {
        return (
            <ErrorBoundary>
                <Outlet />
            </ErrorBoundary>
        )
    }

    const matches = useMatches();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [syncUrl, setSyncUrl] = useState(null);
    const [eventSource, eventSourceStatus] = useEventSource(syncUrl, true);
    const [sidePanelOpen, setSidePanelOpen] = useBrowserStore("side-panel-open", true, localStorage)
    const [syncKey, setSyncKey] = useBrowserStore("sync-key", null);
    const navigate = useNavigate();
    const [ticking, setTicking] = useState(false);

    useEffect(() => {
        runInAction(() => set(store, { Active: matches[matches.length - 1].id }));
    }, [matches]);

    useEffect(() => {
        setSyncUrl(store.Auth?.isAuthenticated && store.SyncEnabled ? `${Website.api}/sync/connect` : null);
    }, [store.Auth?.isAuthenticated, store.SyncEnabled, setSyncUrl]);

    useEffect(() => {
        runInAction(() => set(store, { EventSourceStatus: eventSourceStatus }));
        if (eventSource) {
            toast.dismiss(Toasts.get("sse"))
            switch (eventSourceStatus) {
                case "connecting":
                    Toasts.set("sse", toast.loading("Connecting...", {
                        description: "Attempting to establish a connection to the Sync server.",
                        duration: Infinity,
                    }));
                    break;
                case "close":
                case "error":
                    Toasts.set("sse", toast.error("Disconnected!", {
                        description: "Your connection to the Sync server has been lost.",
                    }));
                    break;
                case "open":
                    Toasts.set("sse", toast.success("Connected!", {
                        description: "Your connection to the Sync server has been established.",
                    }));
                    break;
            }
        }
    }, [eventSource, eventSourceStatus, toast])

    useEventSourceListeners(
        eventSource,
        [
            ["notifications:insert", (event) => Notifications.add(JSON.parse(event.data))],
            ["notifications:delete", (event) => Notifications.remove(JSON.parse(event.data))],
            ["devices:insert", (event) => Devices.add(JSON.parse(event.data))],
            ["devices:delete", (event) => Devices.remove(JSON.parse(event.data))],
            ["devices:update", (event) => Devices.update(JSON.parse(event.data))], // TODO: handle updates
        ],
        [Notifications, Devices],
    );

    const toggles = {
        mobileMenu: () => setMobileMenuOpen(!mobileMenuOpen),
        sidePanel: () => setSidePanelOpen(!sidePanelOpen)
    }

    return (
        <>
            <div className="dark:bg-gray-900 bg-gray-100 min-h-screen">
                {/* Header */}
                <header
                    className="sticky top-0 left-0 right-0 z-50 h-20 bg-white dark:bg-gray-800"
                >
                    <div className="container mx-auto px-4 h-full flex items-center justify-between">
                        <div className="flex items-center justify-between">
                            <div className="flex w-6 h-12 mr-6 cursor-pointer justify-center items-center" onClick={() => setSidePanelOpen(!sidePanelOpen)}>
                                {
                                    sidePanelOpen ?
                                        <PanelLeftClose className="w-6 h-6" />
                                        :
                                        <PanelLeft className="w-6 h-6" />
                                }
                            </div>
                            <div className="flex w-12 h-12 mr-4">
                                {Website.logo ? <img src={Website.logo} alt="Site logo" /> : null}
                            </div>
                            <div className="flex flex-col">
                                <h1 className="font-bold text-xl dark:text-white">
                                    {Website.title || 'OpenPush Lite'}
                                </h1>
                                <p className="text-gray-600 dark:text-gray-400 text-xs">
                                    {Website.description || 'The self-hosted WebPush API'}
                                </p>
                            </div>
                        </div>
                        <Nav store={store} options={{ mobileMenuOpen }} toggles={toggles} />
                    </div>
                    <MobileNav store={store} options={{ mobileMenuOpen }} toggles={toggles} />
                </header>

                {/* Content */}
                <div className="flex">
                    <Sidenav options={{ sidePanelOpen }} store={store} toggles={toggles} />
                    <main className={`flex-grow ${sidePanelOpen ? 'pl-1 ml-12' : 'ml-3'} mr-3`}>
                        <ErrorBoundary>
                            <Outlet />
                        </ErrorBoundary>
                    </main>
                </div>

                {/* Footer */}
                <footer className="z-30  bg-white dark:bg-gray-800 py-8 sticky top-[100vh]">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-col md:flex-row justify-between items-center">
                            <div className="mb-4 md:mb-0">
                                <h2 className="text-md font-bold text-gray-800 dark:text-white mb-2">
                                    Made with indifference by the <a className=" text-gray-600 dark:text-gray-300 underline" rel="noreferrer" target="_blank" href="https://docs.openpu.sh/team">OpenPush team</a>
                                </h2>
                                <p className="text-sm text-gray-600 dark:text-gray-300">
                                    &copy; 2024 OpenPush. All rights reserved.
                                </p>
                            </div>
                            <div className="flex space-x-4">
                                <a href="#" className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">
                                    <Github className="h-6 w-6" />
                                </a>
                                <a href="#" className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">
                                    <Twitter className="h-6 w-6" />
                                </a>
                                <a href="#" className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">
                                    <Linkedin className="h-6 w-6" />
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <Toaster richColors />
        </>
    )
})

export default Layout;