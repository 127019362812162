import { observable } from 'mobx';

let defaults = {
    title: 'OpenPush Lite',
    description: 'The self-hosted WebPush API',
    api: 'https://lite.openpu.sh/api', // The OpenPush Lite demo API
    base: 'https://lite.openpu.sh', // The OpenPush Lite demo site
    logo: 'https://cdn.openpu.sh/images/web/icon-192.png',
};

let Website = observable(defaults);
try {
    Website = {
        ...defaults,
        ...(JSON.parse(process.env.WEBSITE || '{}') || {}),
    }
} catch (err) {
    console.error(err)
}

let Sync = observable({
    service: 'https://lite-sync.openpu.sh', // The OpenPush Lite demo Sync service
});
try {
    Sync = {
        ...Sync,
        ...(JSON.parse(process.env.SYNC || '{}') || {}),
    }
} catch (err) {
    console.error(err)
}

let VapidKey;
try {
    VapidKey = process.env.VAPID;
} catch (err) {
    console.error(err)
}

export {
    Website,
    Sync,
    VapidKey,
}